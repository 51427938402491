let Global = {};

import Icons from './icons';
import Multiselect from 'vue-multiselect';
import Loader from '@/components/Loader';
import IndexHero from '@/components/IndexHero.vue';
import { Vuetable } from 'vuetable-2';
import VueSweetalert2 from 'vue-sweetalert2';
import Paginate from 'vuejs-paginate';
import VueApexCharts from 'vue-apexcharts';
import VJsoneditor from 'v-jsoneditor/src/index';
import DeviceActionDetailRow from './views/devices/components/DeviceActionDetailRow.vue';
import DeviceCommandDetailRow from './views/devices/components/DeviceCommandDetailRow.vue';

Global.install = function (Vue /*options*/) {
    Vue.use(Icons);
    Vue.use(VueSweetalert2);
    Vue.use(VueApexCharts);

    Vue.component('multiselect', Multiselect);
    Vue.component('loader', Loader);
    Vue.component('index-hero', IndexHero);
    Vue.component('vuetable', Vuetable);
    Vue.component('apexchart', VueApexCharts);

    Vue.component('device-action-detail-row', DeviceActionDetailRow);
    Vue.component('device-command-detail-row', DeviceCommandDetailRow);
    Vue.component('paginate', Paginate);

    Vue.filter('trimIsoDate', function (value) {
        return value.substring(0, 19);
    });

    Vue.filter('formatDate', function (value) {
        if (value) {
            return new Date(value).toLocaleString();
        }
    });

    Vue.filter('b64decode', function (value) {
        if (value) {
            return atob(value);
        }
    });

    Vue.filter('capitalize', function (string) {
        if (!string) {
            return '-';
        }

        return string.charAt(0).toUpperCase() + string.slice(1);
    });

    Vue.use(VJsoneditor);

    Vue.directive('click-outside', {
        bind: function (el, binding, vnode) {
            el.event = function (event) {
                // here I check that click was outside the el and his childrens
                if (!(el === event.target || el.contains(event.target))) {
                    // and if it did, call method provided in attribute value
                    vnode.context[binding.expression](event);
                }
            };
            document.body.addEventListener('click', el.event);
        },
        unbind: function (el) {
            document.body.removeEventListener('click', el.event);
        },
    });
};

export default Global;
