import {
    CREATE_CONSUMER_TOPIC,
    DELETE_CONSUMER_TOPIC,
    UPDATE_CONSUMER_TOPIC,
    VIEW_CONSUMER_TOPICS,
    VIEW_PUB_SUB_TOPICS
} from '../../../schema/consumerTopics';
import Consts from '../../../views/connectors/links/consts';

export default {
    namespaced: true,
    state: {
        currentConnector: {
            id: null,
            links: [],
        },
        pubSubTopics: undefined,
    },
    getters: {
        pubSubTopics: (state) => {
            return state.pubSubTopics;
        },
        currentConnectorId: (state) => {
            return state.currentConnector.id;
        },
        currentConnectorLinks: (state) => {
            return state.currentConnector.links;
        },
        currentConnectorLinkById: (state) => (id) => {
            return state.currentConnector.links.find(x => x.id === id);
        },
    },
    mutations: {
        reset: (state) => {
            state.currentConnector = {
                id: null,
                links: [],
            };
            state.pubSubTopics = undefined;
        },
        updatePubSubTopics: (state, pubSubTopics) => {
            state.pubSubTopics = pubSubTopics;
        },
        updateCurrentConnector: (state, payload) => {
            state.currentConnector.id = payload.id;
            state.currentConnector.links = payload.links;
            state.currentConnector.links.forEach(link => {
                link['topic_input'] = link['topic_input'] || Consts.NotFound;
            });
        },
        updateConnectorLinkById: (state, obj) => {
            const index = state.currentConnector.links.findIndex(x => x.id === obj.id);
            if (index !== -1) {
                state.currentConnector.links.splice(index, 1, obj);
            } else {
                state.currentConnector.links.push(obj);
            }
        },
        deleteConnectorLinkById: (state, id) => {
            const index = state.currentConnector.links.findIndex(x => x.id === id);
            if (index !== -1) {
                state.currentConnector.links.splice(index, 1);
            }
        },
    },
    actions: {
        reset: ({ commit }) => {
            commit('reset');
        },
        loadPubSubTopics: ({ commit, rootGetters }, payload) => {
            return new Promise((resolve, reject) =>{
                rootGetters.apollo.query({
                    query: VIEW_PUB_SUB_TOPICS,
                    variables: {
                        projectId: payload.projectId,
                        filters: { 'item_per_page': 1000, 'order_by': { 'id': 'asc' } },
                    },
                    fetchPolicy: 'no-cache',
                    notifyOnNetworkStatusChange: true
                }).then(response => {
                    commit('updatePubSubTopics', response.data.pubSubTopics.data);
                    resolve(response.data.pubSubTopics.data);
                }).catch(error => {
                    reject(error);
                });
            });
        },
        loadCurrentConnectorById: ({ commit, rootGetters }, payload) => {
            return new Promise((resolve, reject) => {
                if (payload.currentId == payload.id) {
                    resolve();
                    return;
                }

                rootGetters.apollo.query({
                    query: VIEW_CONSUMER_TOPICS,
                    variables: {
                        projectId: payload.projectId,
                        receiverId: payload.id,
                        filters: { 'item_per_page': 1000 },
                    },
                    fetchPolicy: 'no-cache',
                    notifyOnNetworkStatusChange: true
                }).then(response => {
                    commit('updateCurrentConnector', { id: payload.id, links: response.data.consumerTopics.data });
                    resolve(response.data.consumerTopics);
                }).catch(error => {
                    reject(error);
                });
            });
        },
        storeOrUpdateConnectorLink: ({ commit, rootGetters }, payload) => {
            return new Promise((resolve, reject) =>{
                let type = payload.create ? 'storeConsumerTopic' : 'updateConsumerTopic';

                rootGetters.apollo.mutate({
                    mutation: payload.create ? CREATE_CONSUMER_TOPIC : UPDATE_CONSUMER_TOPIC,
                    variables: {
                        id: !payload.create ? parseInt(payload.id) : null,
                        projectId: payload.projectId,
                        receiverId: payload.connectorId,
                        input: payload.link,
                    },
                })
                    .then((response) => {
                        commit('updateConnectorLinkById', response.data[type]);
                        resolve(response.data[type]);
                    })
                    .catch((error) => {
                        reject(error);
                    });
            });
        },
        delete: ({ commit, rootGetters }, payload) => {
            return new Promise((resolve, reject) =>{

                rootGetters.apollo.mutate({
                    mutation: DELETE_CONSUMER_TOPIC,
                    variables: {
                        projectId: payload.projectId,
                        receiverId: payload.connectorId,
                        id: payload.id
                    },
                    fetchPolicy: 'no-cache',
                    notifyOnNetworkStatusChange: true
                }).then(({ data, loading }) => {
                    if (!loading && data !== undefined) {

                        commit('deleteConnectorLinkById', payload.id);

                        resolve();
                    }
                }).catch((error) => {
                    console.error(error.message);
                    reject(error);
                });
            });
        },
    }
};
