import gql from 'graphql-tag';

export const VIEW_PUB_SUB_TOPICS = gql`
    query viewPubSubTopics($projectId: Int64!) {
        pubSubTopics(projectId: $projectId) {
            data {
                id
                name
            }
        }
    }
`;

export const VIEW_CONSUMER_TOPICS = gql`
    query viewConsumerTopics($projectId: Int64!, $receiverId: Int64!, $filters: FiltersInput) {
        consumerTopics(projectId: $projectId, receiverId: $receiverId , filters: $filters) {
            data {
                id
                topic_input_id
                topic_input
                topic_output
            }
            pagination {
                total
                last_page
            }
        }
    }
`;

export const CREATE_CONSUMER_TOPIC = gql`
    mutation storeConsumerTopic($projectId: Int64!, $receiverId: Int64!, $input: ConsumerTopicInput!) {
        storeConsumerTopic(projectId: $projectId, receiverId: $receiverId, input: $input) {
            id
            topic_input_id
            topic_input
            topic_output
        }
    }
`;

export const UPDATE_CONSUMER_TOPIC = gql`
    mutation updateConsumerTopic($projectId: Int64!, $receiverId: Int64!, $id: Int64!, $input: ConsumerTopicInput!) {
        updateConsumerTopic(projectId: $projectId, receiverId: $receiverId, id: $id, input: $input) {
            id
            topic_input_id
            topic_input
            topic_output
        }
    }
`;

export const DELETE_CONSUMER_TOPIC = gql`
    mutation deleteConsumerTopic($projectId: Int64!, $receiverId: Int64!, $id: Int64!) {
        deleteConsumerTopic(projectId: $projectId, receiverId: $receiverId, id: $id) {
            id
            topic_input_id
            topic_input
            topic_output
        }
    }
`;